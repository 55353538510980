/*
 * @Description: 
 * @Author: 
 * @Date: 2022-11-22 09:47:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-05-09 15:20:16
 */
import Vue from 'vue'
import ElementUI from 'element-ui'
import Sortable from 'sortablejs'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/css/global.css' // 全局样式
import './assets/fonts/iconfont.css' // 字体样式
import TreeTable from 'vue-table-with-tree-grid'
// 导入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import Print from './plugs/print'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import api from './network/imageUrl'

Vue.config.productionTip = false;
Vue.prototype.api = api
Vue.prototype.alertMessage = function (message, type) {
  this.$message({
    message,
    type
  });
};
Vue.component('tree-table', TreeTable); // 注册为全局可用的组件

Vue.use(ElementUI,VueQuillEditor,Print,Sortable);

// 定义全局的时间过滤器
Vue.filter('dateFormat', function (value) {
  let date = new Date(value * 1000);
  let year = date.getFullYear();
  let month = (date.getMonth() + 1 + '').padStart(2, '0');
  let day = (date.getDate() + '').padStart(2, '0');
  let hour = (date.getHours() + '').padStart(2, '0');
  let minute = (date.getMinutes() + '').padStart(2, '0');
  let second = (date.getSeconds() + '').padStart(2, '0');
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
})

Vue.filter('timestampToTime', function(value){
  let timestamp = value ? Number(value) : null;
  let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
  let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return Y + M + D ;
})


new Vue({
  router,
  store,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')

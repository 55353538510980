/*
 * @Description: 
 * @Author: 
 * @Date: 2023-07-26 11:04:23
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2023-12-11 20:01:32
 */
// const imageUrl = 'https://test.sysongy.mobi:7000' //测试地址
const imageUrl = 'https://pms.sysongy.net'  //生产地址
// const imageUrl = 'https://lng.sysongy.net'   // 新生产地址（20231211）

module.exports = {
  imageUrl
}
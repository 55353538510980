<!--
 * @Description: 
 * @Author: 
 * @Date: 2022-11-22 09:47:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-13 14:16:53
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

  export default {
    name: 'app',
    components: {},
    data() {
      return {
      }
    },
    created() {
    },
    
    methods: {
    }
  }
</script>

<style>
 :root{
    --theme_bg_color:'';
}
.el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #FFF;
    background-color: var(--theme_bg_color) !important; 
    border-color: var(--theme_bg_color) !important;
}

</style>

/*
 * @Description: 
 * @Author: 
 * @Date: 2022-11-22 09:47:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-06-05 09:22:42
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

let Login = () => import('../components/Login');
let Home = () => import('../components/Home');
let Welcome = () => import('../components/Welcome');
let Users = () => import('../components/user/Users');
let Rights = () => import('../components/rights/Rights');
let Roles = () => import('../components/rights/Roles');
let Category = () => import('../components/goods/Category');
let Params = () => import('../components/goods/Params');
let GoodsList = () => import('../components/goods/List');
let GoodsAdd = () => import('../components/goods/listChildComponents/ListAdd');
let Order = () => import('../components/order/Order');
let Internal = () => import('../components/order/Internal');
let External = () => import('../components/order/External')
let Report = () => import('../components/report/Report');
let PlanList = () => import('../components/plan/List');
let ReconciliationList = () => import('../components/financialCenter/reconciliation/List');
let ReconciliationOrder = () => import('../components/financialCenter/reconciliation/ReconciliationComponents/OrderList');
let TradeOrder =() => import('../components/financialCenter/reconciliation/ReconciliationComponents/TradeOrderList');
let FunctionPage = () => import('../components/rights/FunctionPage');
let CapitalList = () => import('../components/financialCenter/capital/List');
let InvoiceList = () => import('../components/financialCenter/invoice/List');
let InvoiceUnapplied = () => import('../components/financialCenter/invoice/invoiceCompontents/UnappliedPage');
let TransportList = () => import('../components/TransportCapacity/List');
let PurchaseList = () => import('../components/customer/purchase/List');
let ManufacturerList = () => import('../components/customer/manufacturer/List');
let TransporEnterprisetList = () => import('../components/customer/transport/List');
let Sysuser = () => import('../components/rights/Employee');
let ThemeList = () => import('../components/rights/Theme'); 
let Check = () => import('../components/plan/AuditList')
let AuditDetails = () => import('../components/plan/PlanAuditComponents/AuditDetails')
let PlanDetails = () => import ('../components/plan/PlanDetails')
let TradeModal = () => import ('../components/financialCenter/reconciliation/ReconciliationComponents/TradeDetails')
let EditTradeModal = () => import ('../components/financialCenter/reconciliation/ReconciliationComponents/EditTradeDetails')
let TradeLookDetails = () => import ('../components/financialCenter/reconciliation/ReconciliationComponents/TradeLookDetails')



Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/home',
    component: Home,
    // redirect: '/pages/biz/gas_factory/order',
    children: [
      {
        path: '/welcome',
        component: Welcome
      },
      {
        path: '/users',
        component: Users
      },
      {
        path: '/rights',
        component: Rights
      },
      {
        path: '/biz2',
        component: Roles
      },
      {
        path: '/categories',
        component: Category
      },
      {
        path: '/params',
        component: Params
      },
      {
        path: '/pages/biz/gas_factory/lng-headstream',
        component: GoodsList
      },
      {
        path: '/add',
        component: GoodsAdd
      },
      {
        path: '/pages/biz/gas_factory/plan',
        component: PlanList
      },
      {
        path: '/pages/biz/gas_factory/finance/reconciliation',
        component: ReconciliationList
      },
      {
        path: '/pages/biz/gas_factory/finance/reconciliation/order',
        component: ReconciliationOrder
      },
      {
        path: '/pages/biz/gas_factory/finance/reconciliation/TradeOrder',
        component: TradeOrder
      },
      {
        path: '/pages/biz/gas_factory/finance/fund',
        component: CapitalList
      },
      {
        path: '/pages/biz/gas_factory/finance/bill',
        component: InvoiceList
      },
      {
        path: '/pages/biz/gas_factory/finance/bill/unapplied',
        component: InvoiceUnapplied
      },
      {
        path: '/pages/biz/gas_factory/transport',
        component: TransportList
      },
      {
        path: '/pages/biz/clients/emption_LNG_enterprise',
        component: PurchaseList
      },
      {
        path: '/pages/biz/clients/factory',
        component: ManufacturerList
      },
      {
        path: '/pages/biz/clients/transportation',
        component: TransporEnterprisetList
      },
      {
        path: '/pages/biz/gas_factory/finance/reconciliation/tradeDetails',
        component: TradeModal
      },
      {
        path: '/pages/biz/gas_factory/finance/reconciliation/editTradeDetails',
        component: EditTradeModal
      },
      {
        path: '/pages/platform/style',
        component: ThemeList
      },
      {
        path: '/pages/biz/gas_factory/order',
        component: Order
      },
      {
        path: '/pages/platform/sysuser',
        component: Sysuser
      },
      {
        path: '/pages/biz/gas_factory/plan/check',
        component: Check
      },
      {
        path: '/pages/biz/gas_factory/plan/check/details',
        component: AuditDetails
      },
      {
        path: '/pages/biz/gas_factory/plan/info',
        component: PlanDetails
      },
      {
        path: '/pages/biz/order/internal',
        component: Internal
      },
      {
        path: '/pages/biz/order/external',
        component: External
      },
      {
        path: '/pages/platform/menu',
        component: FunctionPage
      },
      {
        path: '/reports',
        component: Report
      },
      {
        path:'/pages/biz/gas_factory/finance/reconciliation/tradeDetails/look',
        component: TradeLookDetails
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

// 添加全局前置导航守卫
router.beforeEach((to, from, next) => {
  // 如果访问的是登录页，直接放行
  if (to.path === '/login') return next();
  // 从sessionStorage中取出token值
  let token = localStorage.getItem('token');
  // 如果token不存在，说明没有登录，就强制跳转到登录页
  if (!token) {
    return next('/login');
  }
  next();
});

export default router
